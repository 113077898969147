export type PromotionContent = {
    textDescription: string;
    imgSrc: string;
    url: string;
    id: string;
}

export const CURRENT_PROMOTIONS =  [
    {
        textDescription: "Good Things 2024 featuring KORN SUM41 VIOLENTFEMMES and others!",
        imgSrc: require('@/assets/promoted/goodthings24_oztix_600x300_lineup_onsalenow.gif'),
        url: "https://goodthings.oztix.com.au/?utm_source=oztix&utm_medium=homepage&utm_content=adset",
        id: "good-things-2024-GA-001",
    },
    {
        textDescription: "AusMusic T-shirt Day 2024 - Show your support and help music workers in need today",
        imgSrc: require('@/assets/promoted/austshirtday-2024.gif'),
        url: "https://www.ausmusictshirtday.org.au/?utm_medium=referral&utm_source=homepage&utm_campaign=amtd2024&utm_content=oztix",
        id: "aus-music-tshirt-day-2024-001",
    }
]

export const BACKUP_PROMOTIONS = [
    {
        textDescription: "$100 off your first case of wine at Naked Wines",
        imgSrc: require('@/assets/promoted/naked-wines-001-rs.png'),
        url: "https://www.nakedwines.com.au/ztix100",
        id: "naked-wines-001",
    },
    {
        textDescription: "Escape to K'Gari (Fraser Island) with Sunrover",
        imgSrc: require('@/assets/promoted/sunrover-tours-001-rs.png'),
        url: "https://www.sunrover.com.au/?utm_source=oztix&utm_medium=homepage&utm_content=displayad",
        id: "sunrover-camping-001",
    }
]
